import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useLogList() {
  // Use toast
  const toast = useToast()

  const refImportListTable = ref(null)

  // Table Handlers
  const tableImportColumns = [
    { label: '#', key: 'id', sortable: true },
    { label: 'Type', key: 'type', sortable: true },
    { label: 'Status', key: 'status', sortable: true },
    { label: 'Datum aangemaakt', key: 'timestamp', sortable: true },
    { label: 'Acties', key: 'actions', sortable: false },
  ]

  const perPage = ref(25)
  const totalImport = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [25, 50, 75, 100]
  const showOverlay = ref(true)
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refImportListTable.value ? refImportListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalImport.value,
    }
  })

  const refetchData = () => {
    refImportListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchImports = (ctx, callback) => {
    store
      .dispatch('app-data-import/fetchImports', {
        perPage: perPage.value,
        page: currentPage.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const { items, _meta } = response.data

        callback(items)
        showOverlay.value = false
        totalImport.value = _meta.totalCount
      })
      .catch(() => {
        showOverlay.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ophalen imports',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: '',
          },
        })
      })
  }

  const resolveStatus = status => {
    if (status === 1) return 'In wachtrij'
    if (status === 2) return 'In behandeling'
    if (status === 3) return 'Word verwerkt'
    if (status === 4) return 'Afgerond'
    if (status === 5) return 'Error'
    return 'In behandeling'
  }

  const resolveType = type => {
    if (type === 1) return 'Import facturen'
    return ''
  }

  return {
    fetchImports,
    tableImportColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    refImportListTable,
    refetchData,
    showOverlay,
    resolveStatus,
    totalImport,
    resolveType,
  }
}
